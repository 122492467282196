<template>
    <!-- 剧集抽屉 -->
    <a-drawer
      :title="params.name +'设置'"
      width="700"
      :closable="false"
      :visible="visible"
      @close="onClose"
      :footer-style="{ textAlign: 'right' }"
    >
      <a-form
        ref="RefForm"
        :model="formState"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
      >
        <!-- 前台名称 -->
        <a-form-item
          label="前台名称"
          name="show_name"
          :rules="[{ required: true, message: '请输入' }]"
        >
          <a-input
            placeholder="请输入"
            v-model:value="formState.show_name"
            :maxlength="12"
          />
        </a-form-item>
        <!-- 副标题 -->
        <a-form-item
          label="副标题"
          name="title"
          :rules="[{ required: true, message: '请输入' }]"
        >
          <a-input
            placeholder="请输入"
            v-model:value="formState.title"
            :maxlength="12"
          />
        </a-form-item>
        <a-form-item
            label="活动"
            name="activity_id"
            :rules="[{ required: true, message: '请选择活动' }]"
        >
        <a-select
          show-search
          style="width: 350px"
          placeholder="请选择"
          @change="handleOnchange"
          v-model:value="formState.activity_id"
          :filter-option="filterOption"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="drama in activityList"
            :key="`${drama.id}-${drama.title}`"
            :value="drama.id"
          >
            {{ drama.title}}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 图片 -->
          <!-- 图片 -->
        <a-form-item
        label="图片"
        name="img"
        :rules="[{ required: true }]"
      >
        <!-- 上传组件 -->
        <Upload
          accept=".png,.jpg,.gif"
          uploadSource="ActivityAdd"
          :fileNumber="1"
          :imgSizeMode="22"
          :imgSizeWidth="630"
          :imgSizeHeight="800"
          imgSizeError="图片最小尺寸为 630 * 800"
          imgScaleError="图片尺寸比例不正确"
          :beforeUploadPro="beforeUploadPro"
          :fileList="formState.img"
          :uploadBucket="$pub.BUCKET_PUB()"
        ></Upload>
        <div class="form-upload-hint">支持.png .jpg .gif，630×800的图片</div>
        <div class="form-upload-list" v-if="formState.img.length">
          <ImageView
            class="form-upload-item"
            v-for="(item, index) in formState.img"
            :key="index"
            :url="$pub.CDN_URL(item.url)"
            :fileJson="item"
            @delete="touchDeleteCover('img', index)"
          >
          </ImageView>
        </div>
      </a-form-item>


      </a-form>
      <!-- 尾部 -->
      <template #footer>
        <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
        <a-button type="primary" @click="touchSubmit">确定</a-button>
      </template>
      <!-- 加载 -->
      <Loading :loading="isLoading"></Loading>
    </a-drawer>
  </template>
  
  <script setup>
  import { ref, reactive, defineExpose } from 'vue'
  import Loading from '@/components/Loading'
  import Upload from '@/components/Upload'
  import Pub from '@/utils/public'
  import { message } from 'ant-design-vue'
  import ImageView from '@/components/ImageView'
  import { frontTaskDetail, frontTaskEdit, frontBannerActivityList} from '@/api/operate'
  import { AdvTypesWx, AdvTimeInterval } from '@/utils/constantList'
  
  // emit
  const emit = defineEmits(['success'])
  // Form实例
  const RefForm = ref(null)
  // 抽屉展示状态
  let visible = ref(false)
  // 加载
  let isLoading = ref(false)
  // 父组件传进来的参数
  let params = ref({})
  // 活动列表
  let activityList = ref([])
  // 9 加企微好友  10关注公众号
  let formState = reactive({
    // 前台名称
    show_name: undefined,
    // 副标题
    title: undefined,
    // 活动id
    activity_id: undefined,
    // 活动名称
    activity_name: undefined,
    //引导图片地址
    lead_image: undefined,
    // 图片
    img : []
  })

  // 打开抽屉
  function showDrawer (obj) {
    // 展开
    visible.value = true
    // 参数保存
    params.value = obj
    // 获取配置详情
    getFrontChargeDetail()

    //初始化获取列表
    getSelectList()
  }
  
  // 获取配置详情
  function getFrontChargeDetail () {
    isLoading.value = true
    frontTaskDetail({
      task_id: params.value.id
    }).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        // 取出参数值
        for (let i in formState) {
          formState[i] = data[i]
        }
        formState.img = data.lead_image === '' ? [] : [{
            url: data.lead_image
        }]
        formState.activity_id = formState.activity_id === 0 ? undefined : formState.activity_id;
        } else {
            message.error(res.message || msg)
        }
    })
  }
  
  // 获得活动列表
  function getSelectList () {
  frontBannerActivityList().then(res => {
    const { code, msg, data } = res
    if (code === 0) {
      activityList.value = data
    }
  })
}

  // 关闭抽屉
  function onClose () {
    if (!isLoading.value) {
      // 表单重置
      RefForm.value.resetFields()
      visible.value = false
    }
  }
  function handleOnchange(e) {
    activityList.value.some(
      function (item, index, arry) {
        if (e === item.id) {
          formState.activity_name = item.title
          return true
        }
        return false
      }
    )

      console.log(e)
  }
  // 移除封面
  function touchDeleteCover (coverKey, index) {
    formState[coverKey].splice(index, 1)
  }
// 模糊匹配
  function filterOption (input, option) {
    return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
  }
// 准备上传
  function beforeUploadPro (file) {
    if (!file.type.includes('image')) {
      message.error('请上传图片类型的文件')
      return false
    }
    return true
  }

  // 提交
  function touchSubmit () {
    RefForm.value.validate().then(() => {
      isLoading.value = true
      // 参数准备
      formState.lead_image = formState.img[0].url
      const query = {
        task_id: params.value.id,
        ...formState
      }
      frontTaskEdit(query).then(res => {
        isLoading.value = false
        const { code, msg, data } = res
        if (code === 0) {
          message.success('操作成功')
          onClose()
          // 更新父组件
          emit('success')
        } else {
          message.error(res.message || msg)
        }
      })
    })
  }
  
  // 暴露出去
  defineExpose({
    showDrawer
  })
  
  </script>
  
  <style lang="less" scoped>
  .form-item-clear {
    margin-bottom: 0;
  }
  .play-item {
    margin-bottom: 16px;
  }
  .ant-btn-link {
    padding: 0;
    margin-left: 8px;
  }
  .form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  // width: 210px;
  // height: 266px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
  </style>