<template>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :loading="isLoading"
    :pagination="false"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record, index }">
      <!-- 是否开启 -->
      <template v-if="column.key === 'status'">
        <a-switch
          checked-children="开"
          un-checked-children="关"
          :disabled="isLoading"
          :checked="!!record.status"
          @change="changeSwitch(record.id, $event)"
        />
      </template>
      <!-- 设置 -->
      <template v-if="column.key === 'is_daily'">
        {{ record[column.key] === 1 ? '每日重复' : '一次性' }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <!-- 设置 -->
        <a-button
          type="link"
          @click="handleEdit(record)"
        >
          设置
        </a-button>
        <!-- 上移：第一组禁用 -->
        <a-button
          type="link"
          :disabled="index === 0"
          @click="handleSort(record, 'up')"
        >
          上移
        </a-button>
        <!-- 下移：最后一组禁用 -->
        <a-button
          type="link"
          :disabled="index === dataSource.length - 1"
          @click="handleSort(record, 'down')"
        >
          下移
        </a-button>
      </template>
    </template>
  </a-table>
  <!-- 签到编辑 -->
  <SignEdit ref="RefSignEdit" @success="getFrontSourceList"></SignEdit>
  <!-- 公众号、我的小程序、桌面启动编辑，添加桌面编辑 -->
  <StartEdit ref="RefStartEdit" @success="getFrontSourceList"></StartEdit>
  <!-- 大转盘编辑 -->
  <WheelEdit ref="RefWheelEdit" @success="getFrontSourceList"></WheelEdit>
  <!-- 看广告 -->
  <LookAdEdit ref="RefLookAdEdit" @success="getFrontSourceList"></LookAdEdit>
  <!-- 每日追剧 -->
  <StarDramaEdit ref="RefStarDramaEdit" @success="getFrontSourceList"></StarDramaEdit>
  <!-- 添加企微好友，关注公众号 -->
  <ActivityEdit ref="RefActivityEdit" @success="getFrontSourceList"></ActivityEdit>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { FrontSource } from '@/utils/constantList'
import { message } from 'ant-design-vue'
import SignEdit from './SignEdit'
import StartEdit from './StartEdit'
import WheelEdit from './WheelEdit'
import LookAdEdit from './LookAdEdit'
import StarDramaEdit from './StarDramaEdit'
import ActivityEdit from './ActivityEdit'
import { frontSourceList, frontSourceSort, frontTaskSwitch } from '@/api/operate'

// 签到设置组件实例
let RefSignEdit = ref(null)
// 启动设置组件实例
let RefStartEdit = ref(null)
// 大转盘设置组件实例
let RefWheelEdit = ref(null)
// 看广告设置组件实例
let RefLookAdEdit = ref(null)
// 每日追剧设置组件实例
let RefStarDramaEdit = ref(null)
// 公众号、企微好友组件实例
let RefActivityEdit = ref(null)
// props
const props = defineProps({
  platform: Number
})
// source类型
const source = FrontSource.task
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 列数据源
let columns = ref([
  {
    title: '排序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '任务名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '前台名称',
    dataIndex: 'show_name',
    key: 'show_name'
  },
  {
    title: '副标题',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: '任务类型',
    dataIndex: 'is_daily',
    key: 'is_daily'
  },
  {
    title: '是否开启',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount(() => {
  // 获取配置列表
  getFrontSourceList()
})

// 获取配置列表
function getFrontSourceList () {
  isLoading.value = true
  frontSourceList({
    source,
    platform_id: props.platform
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      dataSource.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 是否开启
function changeSwitch (id, boolean) {
  const params = {
    id,
    status: boolean ? 1 : 0
  }
  frontTaskSwitch(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 排序板块
function handleSort (record, type) {
  isLoading.value = true
  const params = {
    source,
    id: record.id,
    type
  }
  frontSourceSort(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 更新列表
      getFrontSourceList()
    } else {
      message.error(res.message || msg)
    }
  })
}

// 编辑
function handleEdit (record) {
  // 任务类型 1：每日签到  2:看广告  3：大转盘  4：公众号关注启动  5:小程序入口启动  6:桌面启动  7:添加桌面  8:长期订阅 12:转发小程序
  const ref = {
    // 签到组件
    1: RefSignEdit,
    // 看广告组件
    2: RefLookAdEdit,
    // 大转盘组件
    3: RefWheelEdit,
    // 4、5、6、7、8、12用一个组件
    4: RefStartEdit,
    5: RefStartEdit,
    6: RefStartEdit,
    7: RefStartEdit,
    8: RefStartEdit,
    12: RefStartEdit,
    // 9:添加企微好友  10关注公众号
    9: RefActivityEdit,
    10: RefActivityEdit,
    // 每日追剧
    11: RefStarDramaEdit,
  }
  ref[record.task_type].value.showDrawer({
    // id
    id: record.id,
    // name
    name: record.name,
    // 任务类型
    type: record.task_type
  })
}

</script>

<style lang="less" scoped>
.table-view {
  margin-top: 10px;
  :deep(.ant-table-cell) {
    vertical-align: top;
  }
  .play-name {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
    .gap {
      margin: 0 8px;
    }
  }
}
a[disabled] {
  pointer-events: none;
  cursor: default;
}
.tag-type {
  margin-left: 20px;
  background: #eee;
}
</style>