<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="params.name +'设置'"
    width="800"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 18 }"
    >
      <!-- 前台名称 -->
      <a-form-item
        label="前台名称"
        name="show_name"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.show_name"
          :maxlength="12"
        />
      </a-form-item>
      <!-- 副标题 -->
      <a-form-item
        label="副标题"
        name="title"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.title"
          :maxlength="12"
        />
      </a-form-item>
      <!-- 每日免费抽奖次数 -->
      <a-form-item
        label="每日免费抽奖次数"
        name="day_free_draw"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input-number
          placeholder="请输入"
          v-model:value="formState.day_free_draw"
          addon-after="次"
          min="0"
        />
      </a-form-item>
      <!-- 每日广告抽奖次数 -->
      <a-form-item
        label="每日广告抽奖次数"
        name="day_limit_draw"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input-number
          placeholder="请输入"
          v-model:value="formState.day_limit_draw"
          addon-after="次"
          min="0"
        />
      </a-form-item>
      <!-- 广告类型 -->
      <a-form-item
        label="广告类型"
        name="ad_type"
        :rules="[{ required: true, message: '请选择' }]"
      >
        <a-select
          v-model:value="formState.ad_type"
          style="width: 50%;"
          placeholder="请选择"
        >
          <a-select-option
            v-for="item in AdvTypesWx"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 广告参数 -->
      <a-form-item
        label="广告参数"
        name="ad_id"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <a-input
          placeholder="请输入 type,adid，若没有type，直接输入 adid"
          style="width: 50%;"
          v-model:value="formState.ad_id"
        />
      </a-form-item>
      <!-- 转盘配置 -->
      <a-form-item
        label="转盘配置"
        style="margin-bottom: 0px;"
        :rules="[{ required: true }]"
      >
        <div
          v-for="(prize, index) in formState.item"
          :key="index"
          class="prize-item"
        >
          <span>奖项{{ index + 1 }}：</span>
          <!-- 类型 -->
          <a-form-item
            :name="['item', index, 'type_id']"
            :rules="[{ required: true, message: '请选择' }]"
          >
            <a-select
              v-model:value="prize.type_id"
              style="width: 120px; margin-right: 12px"
            >
              <a-select-option :value="1">虚拟币</a-select-option>
              <a-select-option :value="2">五折卡</a-select-option>
              <a-select-option :value="3">会员</a-select-option>
            </a-select>
          </a-form-item>
          <!-- 数量 -->
          <a-form-item
            :name="['item', index, 'type_num']"
            :rules="[{ required: true, message: '请输入' }]"
          >
            <a-input-number
              placeholder="请输入"
              v-model:value="prize.type_num"
              :addon-after="prize.type_id == 1 ? '个' : '天'"
              min="0"
              style="width: 130px; margin-right: 24px"
            />
          </a-form-item>
          <!-- 奖项数量 -->
          <span>数量：</span>
          <a-form-item
            :name="['item', index, 'draw_count']"
            :rules="[{ required: true, message: '请输入' }]"
          >
            <a-input-number
              placeholder="请输入"
              v-model:value="prize.draw_count"
              :addonAfter="Pub.KEEP_NUMBER_DECIMAL(prize.draw_count / totalCount * 100, 1)+ '%'"
              min="0"
              style="width: 150px"
            />
          </a-form-item>
        </div>
      </a-form-item>
      <!-- 看广告 -->
      <a-form-item
        label="看广告再领"
        name="title"
        :rules="[{ required: true }]"
      >
        <a-switch
          checked-children="开"
          un-checked-children="关"
          style="margin-top: 6px;"
          v-model:checked="formState.adv.is_on"
        />
        <template v-if="formState.adv.is_on">
          <!-- 广告类型 -->
          <a-form-item
            :rules="[{ required: true, message: '请选择' }]"
            :name="['adv', 'ad_type']"
          >
            <a-select
              v-model:value="formState.adv.ad_type"
              style="margin-top: 16px;"
              placeholder="请选择"
            >
              <a-select-option
                v-for="item in AdvTypesWx"
                :key="item.value"
                :value="item.value"
              >
                {{ item.text }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- 广告参数 -->
          <a-form-item
            :rules="[{ required: true, message: '请输入' }]"
            :name="['adv', 'ad_id']"
          >
            <a-input
              placeholder="请输入 type,adid，若没有type，直接输入 adid"
              addon-after="广告参数"
              v-model:value="formState.adv.ad_id"
              :maxlength="100"
            />
          </a-form-item>
          <!-- 奖励类型 -->
          <a-form-item
            :rules="[{ required: true, message: '请选择' }]"
          >
            <div class="flex-box">
              <!-- 类型单选 -->
              <a-radio-group
                v-model:value="formState.adv.reward_type"
                :options="rewardOptions" 
              />
              <!-- 奖励值：随机奖励时展示 -->
              <a-form-item
                v-if="formState.adv.reward_type === 1"
                style="height: 100%; margin: 0 0 0 10px;"
              >
                <a-input-group compact>
                  <a-form-item
                    :rules="[{ required: true, validator: Pub.VALIDATOR_POSITIVE_INTEGER }]"
                    :name="['adv', 'coin_number_min']"
                  >
                    <a-input
                      v-model:value="formState.adv.coin_number_min"
                      style="width: 90px; text-align: center"
                      placeholder="最小值"
                    />
                  </a-form-item>
                  <a-input
                    style="width: 30px; border-left: 0; pointer-events: none; background-color: #fff"
                    placeholder="~"
                    disabled
                  />
                  <a-form-item
                    :rules="[{ required: true, validator: Pub.VALIDATOR_POSITIVE_INTEGER }]"
                    :name="['adv', 'coin_number_max']"
                  >
                    <a-input
                      v-model:value="formState.adv.coin_number_max"
                      style="width: 155px; text-align: center;"
                      placeholder="最大值"
                      addon-after="虚拟币"
                      class="no-left-border"
                    />
                  </a-form-item>
                </a-input-group>
              </a-form-item>
            </div>
          </a-form-item>
        </template>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, computed } from 'vue'
import Loading from '@/components/Loading'
import { message } from 'ant-design-vue'
import Pub from '@/utils/public'
import { frontTaskDetail, frontTaskEdit } from '@/api/operate'
import { AdvTypesWx } from '@/utils/constantList'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 父组件传进来的参数
let params = ref({})
// 4：公众号关注启动  5:小程序入口启动  6:桌面启动  7:添加桌面 8:长期订阅
// 公众号启动时，增加 skip_url 跳转参数
let formState = reactive({
  // id
  id: undefined,
  // 任务id
  task_id: undefined,
  // 前台名称
  show_name: undefined,
  // 副标题
  title: undefined,
  // 每日免费抽奖次数
  day_free_draw: undefined,
  // 每日广告抽奖次数
  day_limit_draw: undefined,
  // 转盘配置
  item: [],
  // 广告类型
  ad_type: undefined,
  // 广告参数
  ad_id: undefined,
  adv: {
    // 开关
    is_on: false,
    // 广告类型
    ad_type: 1,
    // 广告参数
    ad_id: undefined,
    // 奖励类型
    reward_type: 1,
    // 奖励值
    coin_number_min: 0,
    coin_number_max: 0
  }
})
const rewardOptions = [
  { label: '双倍奖励', value: 2 },
  { label: '随机奖励', value: 1 },
]
// 使用计算属性，实时计算所有奖项总数
let totalCount = computed(() => {
  let count = 0
  formState.item.forEach(item => {
    count += item.draw_count
  })
  return count
})

// 打开抽屉
function showDrawer (obj) {
  // 展开
  visible.value = true
  // 参数保存
  params.value = obj
  // 获取配置详情
  getFrontChargeDetail()
}

// 获取配置详情
function getFrontChargeDetail () {
  isLoading.value = true
  frontTaskDetail({
    task_id: params.value.id
  }).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 取出参数值
      for (let i in formState) {
        formState[i] = data[i]
      }
      // 广告开关
      formState.adv.is_on = !!data.adv.is_on
      // 最小值
      formState.adv.coin_number_min = data.adv.coin_number ? data.adv.coin_number.split(',')[0] : undefined
      // 最大值
      formState.adv.coin_number_max = data.adv.coin_number ? data.adv.coin_number.split(',')[1] : undefined
    } else {
      message.error(res.message || msg)
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 表单重置
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 深拷贝表单参数
    let formStateCopy = Pub.deepClone(formState)
    // 开关
    formStateCopy.adv.is_on = formStateCopy.adv.is_on ? 1 : 0
    // 奖励值
    formStateCopy.adv.coin_number = formStateCopy.adv.coin_number_min + ',' + formStateCopy.adv.coin_number_max
    // 删除自用的最大最小值
    delete(formStateCopy['adv']['coin_number_min'])
    delete(formStateCopy['adv']['coin_number_max'])
    const query = {
      ...formStateCopy
    }
    frontTaskEdit(query).then(res => {
      isLoading.value = false
      const { code, msg, data } = res
      if (code === 0) {
        message.success('操作成功')
        onClose()
        // 更新父组件
        emit('success')
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-item-clear {
  margin-bottom: 0;
}
.play-item {
  margin-bottom: 16px;
}
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
}
.prize-item {
  display: flex;
  // align-items: center;
  margin-bottom: 24px;
  .ant-form-item {
    margin-bottom: 0;
  }
  span {
    margin-right: 4px;
    line-height: 32px;
  }
}
.flex-box {
  display: flex;
  align-items: center;
  height: 32px;
  .ant-radio-group {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .no-left-border {
    /deep/ .ant-input {
      border-left: 0;
    }
  }
}
</style>